export default class Polygon{
	constructor(){
		this.polygonMode = true;
		this.pointArray = [];
		this.lineArray = [];
		this.activeShape = null;
		this.activeLine = null;
	}
	
	reset(){
		
		$.each(this.pointArray,function(index,point){
            _editor.canvas.remove(point);
        });
        $.each(this.lineArray,function(index,line){
            _editor.canvas.remove(line);
        });
        _editor.canvas.remove(this.activeShape).remove(this.activeLine);
		
		this.pointArray.splice(0, this.pointArray.length);
		this.lineArray.splice(0, this.lineArray.length);
		
		this.activeShape = null;
		this.activeLine = null;
		
		_editor.polygonMode = false;
        _editor.canvas.selection = true;
	}
	
	addPoint(options){
		var random = Math.floor(Math.random()*(400 - 100 + 1)) + 100;
		var id = new Date().getTime() + random;
		var sY = window.scrollY;
		var posY = options.e.layerY - sY;
		var circle = new fabric.Circle({
			radius: 5,
            fill: '#ffffff',
            stroke: '#333333',
            strokeWidth: 0.5,
            left: (options.e.layerX/_editor.canvas.getZoom()),
            top: (posY/_editor.canvas.getZoom()),
            selectable: false,
            hasBorders: false,
            hasControls: false,
            originX:'center',
            originY:'center',
            id:id
		});
		
		if(this.pointArray.length == 0){
            circle.set({
                fill:'red'
            })
        }
        var points = [(options.e.layerX/_editor.canvas.getZoom()),(posY/_editor.canvas.getZoom()),(options.e.layerX/_editor.canvas.getZoom()),(posY/_editor.canvas.getZoom())];
        this.line = new fabric.Line(points, {
            strokeWidth: 2,
            fill: '#999999',
            stroke: '#999999',
            class:'line',
            originX:'center',
            originY:'center',
            selectable: false,
            hasBorders: false,
            hasControls: false,
            evented: false
        });
        if(this.activeShape){
            var pos = _editor.canvas.getPointer(options.e);
            var points = this.activeShape.get("points");
            points.push({
                x: pos.x,
                y: pos.y
            });
            var polygon = new fabric.Polygon(points,{
                stroke:'#333333',
                strokeWidth:1,
                fill: '#cccccc',
                opacity: 0.1,
                selectable: false,
                hasBorders: false,
                hasControls: false,
                evented: false
            });
            _editor.canvas.remove(this.activeShape);
            _editor.canvas.add(polygon);
            this.activeShape = polygon;
            _editor.canvas.renderAll();
        }
        else{
            var polyPoint = [{x:(options.e.layerX/_editor.canvas.getZoom()),y:(posY/_editor.canvas.getZoom())}];
            var polygon = new fabric.Polygon(polyPoint,{
                stroke:'#333333',
                strokeWidth:1,
                fill: '#cccccc',
                opacity: 0.1,
                selectable: false,
                hasBorders: false,
                hasControls: false,
                evented: false
            });
            this.activeShape = polygon;
            _editor.canvas.add(polygon);
        }
        this.activeLine = this.line;

        this.pointArray.push(circle);
        this.lineArray.push(this.line);

        _editor.canvas.add(this.line);
        _editor.canvas.add(circle);
        _editor.canvas.selection = false;
	}
	
	generatePolygon(pointArray){
		var points = new Array();
        $.each(this.pointArray,function(index,point){
            points.push({
                x:point.left,
                y:point.top
            });
            _editor.canvas.remove(point);
        });
        $.each(this.lineArray,function(index,line){
            _editor.canvas.remove(line);
        });
        _editor.canvas.remove(this.activeShape).remove(this.activeLine);
        var polygon = new fabric.Polygon(points,{
            stroke:'#333333',
            strokeWidth:0.5,
            fill: 'red',
            opacity: 1,
			type: 'polygon'
        });
        _editor.canvas.add(polygon);

        this.activeLine = null;
        this.activeShape = null;
		this.pointArray.splice(0, this.pointArray.length);
		this.lineArray.splice(0, this.lineArray.length);
		_editor.zoomLocked = false;
        _editor.isPolygonMode = false;
        _editor.canvas.selection = true;
		
	}
};