export default class Toolbar{
	constructor(selector, tools, fileops){
		this.el = $(selector);
		this.top = this.el.children('.top-pane');
		this.bottom = this.el.children('.bottom-pane');
		this.top.html('');
		this.bottom.html('');
		
		tools.forEach(tool=>{
			var t = $('<button class="btn-tool'+(tool.important?' primary':'') + (tool.isMode?' btn-tool-mode':'')+'"'+(tool.hidelabel?' title="' + tool.label + '"':'')+'>' + (tool.icon?'<i class="fi ' + tool.icon + '"></i>':'') + (!tool.hidelabel?'&nbsp;'+tool.label:'') + '</button>');
			t.click((e)=>{
				this.el.find('.btn-tool').removeClass('active');
				$(e.currentTarget).addClass('active');
				tool.callback()
			});
			this.top.append(t);
		});
		
		fileops.forEach(tool=>{
			var t = $('<button class="btn-tool'+(tool.important?' primary':'')+(tool.isMode?' btn-tool-mode':'')+'"'+(tool.hidelabel?' title="' + tool.label + '"':'')+'>' + (tool.icon?'<i class="fi ' + tool.icon + '"></i>':'') + (!tool.hidelabel?'&nbsp;'+tool.label:'') + '</button>');
			t.click((e)=>{
				this.el.find('.btn-tool').removeClass('active');
				$(e.currentTarget).addClass('active');
				tool.callback()
			});
			this.bottom.append(t);
		});
	}
}