const dtools = require('html-loader!./drawing_tools.html');

class PropType{
	constructor(){
		
	}
}

class IntProp extends PropType{
	constructor(){
		super();
	}
}

class ShadowProp extends PropType{
	constructor(value, label, options){
		super();
		this.label = $('<label>'+label+'</label>');
		this.value = value?value:{offsetX: 0, offsetY: 0, blur: 0, color: '#00000040'};
		
		var x = $('<input name="x" value="0" />');
		x.val(this.value.offsetX);
		var y = $('<input name="y" value="0" />');
		y.val(this.value.offsetY);
		var blur = $('<input type="range" min="0" max="30" step="1" />');
		blur.val(this.value.blur);
		
		var xy = $('<fieldset data-width="100" data-height="100" class="pad"></div>');
		xy.append(x).append(y);
		
		var sOffset = $('<div class="shadow-offset"><label>Desplazamiento</label></div>');
		sOffset.append(xy);
		
		var sBlur = $('<div class="shadow-blur"><label>Desenfoque</label></div>');
		sBlur.append(blur);
		
		this.propEl = $('<div class="prop shadow-prop"></div>');
		this.propEl.append(this.label);
		
		this.controls = $('<div class="controls"></div>');
		this.controls.append(sOffset);
		this.controls.append(sBlur);
		this.propEl.append(this.controls);
		_editor.propEditor.propList.append(this.propEl);
		
		blur.on('input', (e)=>{
			this.value = Object.assign(this.value, {blur: blur.val()});
			var ao = _editor.canvas.getActiveObject();
			if(ao){
				ao.setShadow(this.value);
			}
			_editor.canvas.renderAll();
		});
		
		xy.xy({
			displayPrevious: false,
			min: -50, max: 50,
			fgColor: '#222',
			bgColor: '#ddd',
			change: (val)=>{
				this.value = Object.assign(this.value, {offsetX: val[0], offsetY: -val[1]});
				var ao = _editor.canvas.getActiveObject();
				if(ao){
					ao.setShadow(this.value);
				}
				_editor.canvas.renderAll();
			}
		});
	}
}

class SelectProp extends PropType{
	constructor(attr, value, label, options){
		super();
		this.attribute = attr;
		this.value = value;
		this.label = $('<label>' + label + '</label>');
		this.control = $('<select></select>');
		Object.keys(options).forEach(key=>{
			this.control.append('<option value="' + key + '">' + options[key] + '</option>');
		});
		this.propEl = $('<div class="prop select-prop"></div>');
		this.propEl.append(this.label);
		this.propEl.append(this.control);
		_editor.propEditor.propList.append(this.propEl);
		this.control.change((e)=>{
			this.value = this.control.val();
			var ao = _editor.canvas.getActiveObject();
			
			if(ao){
				if(ao.type === 'activeSelection'){
					ao.getObjects().forEach(o=>o.set(this.attribute, this.value));
				}else{
					ao.set(this.attribute, this.value);
				}
			}
			_editor.canvas.renderAll();
		});
	}
}

class FloatProp extends PropType{
	constructor(attr, value, label, opts){
		super();
		this.options = {min: 0, max: 1, step: 0.01};
		this.options = Object.assign(this.options, opts);
		
		this.attribute = attr;
		this.value = value;
		this.label = $('<label>' + label + '</label>');
		this.control = $('<input type="range" min="'+this.options.min+'" max="'+this.options.max+'" step="'+this.options.step+'" />');
		this.control.val(this.value);
		this.propEl = $('<div class="prop float-prop"></div>');
		this.propEl.append(this.label);
		this.propEl.append(this.control);
		_editor.propEditor.propList.append(this.propEl);
		this.control.on('input', (e)=>{
			this.value = parseFloat(this.control.val());
			var ao = _editor.canvas.getActiveObject();
			if(ao){
				
				if(ao.type === 'activeSelection'){
					ao.getObjects().forEach(o=>{
						if(Array.isArray(this.attribute)){
							this.attribute.forEach(at=>o.set(at, this.value));
						}else{
							o.set(this.attribute, this.value);
						}
					});
				}else{
					if(Array.isArray(this.attribute)){
						this.attribute.forEach(at=>ao.set(at, this.value));
					}else{
						ao.set(this.attribute, this.value);
					}
				}
			}
			_editor.canvas.renderAll();
		});
	}
}

class ColorProp extends PropType{
	constructor(attr, value, label){
		super();
		this.attribute = attr;
		this.value = value;
		this.label = $('<label>' + label + '</label>');
		this.control = $('<div class="color-value" style="background-color: ' + this.value + ';"></div>');
		this.propEl = $('<div class="prop color-prop"></div>');
		this.propEl.append(this.label);
		this.propEl.append(this.control);
		this.picker = AColorPicker.createPicker(this.propEl.get(0));
		this.picker.hide();
		this.picker.on('change', (e, ee, eee)=>{
			this.value = e.color; this.control.css('background-color', e.color);
			var ao = _editor.canvas.getActiveObject();
			if(ao){
				if(ao.type === 'activeSelection'){
					ao.getObjects().forEach(o=>o.set(this.attribute, this.value));
				}else{
					ao.set(this.attribute, this.value);
				}
			}
			_editor.canvas.renderAll();
		});
		_editor.propEditor.propList.append(this.propEl);
		
		this.control.click((e)=>{
			if(_editor.currentColorPicker){ _editor.currentColorPicker.hide(); }
			e.stopPropagation();
			_editor.currentColorPicker = this.picker;
			this.picker.show();
			/*
			this.picker = new ColorPicker(this.control.get(0));
			this.picker.onChange = (color)=>{
				this.control.style.backgroundColor = color.rgbaString;
				this.value = color.rgbaString;
				var ao = _editor.canvas.getActiveObject();
				if(ao){ ao.set(this.attribute, this.value); }
				_editor.canvas.renderAll();
			};
			*/
		});
	}
}

class DrawingTools{
	constructor(){
		var dt = $(dtools);
		dt.find('.brush-size').on('input', (e)=>{
			_editor.canvas.freeDrawingBrush.width = e.currentTarget.value;
		});
		dt.find('.brush-color').on('change', (e)=>{
			_editor.canvas.freeDrawingBrush.color = e.currentTarget.value; 
		});
		return dt;
	}
}

export default class PropertyEditor{
	constructor(canvas, el){
		this.el = $(el);
		this.canvas=canvas;
	}
	
	clear(){
		this.el.html('');
	}
	
	showDrawTools(){
		this.el.html('<strong>Propiedades de pincel</strong>');
		this.propList = $('<div class="prop-list"></div>');
		this.el.append(this.propList);
		
		this.propList.append(new DrawingTools());
	}
	
	getActiveObjectProps(){
		var o = this.canvas.getActiveObject();
		this.el.html('<strong>Propiedades</strong>');
		this.propList = $('<div class="prop-list"></div>');
		this.el.append(this.propList);
		
		var props = [];
		if(['box','text','textbox','polygon','circle','rect','path','activeSelection'].indexOf(o.type) !== -1){
			props.push(new ColorProp('fill', o.get('fill'), 'Color de relleno'));
			props.push(new ColorProp('stroke', o.get('stroke'), 'Color de trazo'));
			props.push(new FloatProp('strokeWidth', o.get('strokeWidth'), 'Grosor del trazo', {min: 0, max: 30, step: 0.1}));
			if(o.type === 'box'){
				props.push(new FloatProp(['rx','ry'], o.get('rx'), 'Radio', {min: 0, max: 50, step: 0.1}));
			}
			if(o.type === 'circle'){
				props.push(new FloatProp('radius', o.get('radius'), 'Radio', {min: 5, max: 100, step: 1}));
			}
			props.push(new FloatProp('opacity', o.get('opacity'), 'Opacidad', {min: 0, max: 1, step: 0.01}));
			props.push(new ShadowProp(o.get('shadow'), 'Sombra'));
		}
		
		if(['image'].indexOf(o.type) !== -1){
			props.push(new FloatProp('opacity', o.get('opacity'), 'Opacidad', {min: 0, max: 1, step: 0.01}));
			props.push(new ShadowProp(o.get('shadow'), 'Sombra'));
		}
		
		if(['text','textbox'].indexOf(o.type) !== -1){
			props.push(new SelectProp('fontFamily', o.get('fontFamily'), 'Fuente', {
				'Arial': 'Arial',
				'Courier': 'Courier New',
				'Times': 'Times New Roman',
				'Pacifico': 'Pacifico'
			}));
			props.push(new SelectProp('fontSize', o.get('fontSize'), 'Tamaño Fuente', {
				8: 8,
				9: 9,
				10: 10,
				12: 12,
				16: 16,
				24: 24,
				48: 48,
				72: 72,
				96: 96
			}));
		}
	}
}