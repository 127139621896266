import $ from 'jquery';
require('./jquery.kontrol');
require('jquery-contextmenu');
const AColorPicker = require('a-color-picker');
window.AColorPicker = AColorPicker;
import {fabric} from 'fabric';
import History from './History';
import Polygon from './Polygon';
import ImageUploaderForm from './ImageUploaderForm';
import SVGGallery from './SVGGallery';
import PropertyEditor from './PropertyEditor';
import Toolbar from './Toolbar';
 
export default class Editor{
	static loadFromEAN(ean){
		return new Promise((resolve, reject)=>{
			$.ajax({
				type: 'POST',
				dataType: 'json',
				url: Editor.endpoint + 'geteandata.php?ean=' + ean,
				success: res=>{
					resolve(res);
				},
				error: (err)=>{
					reject(err);
				}
			});
		});
	}
	
	update(options){
		this.originalPath = options.info.original;
		this.originalFileName = options.info.o_filename;
		this.documentWidth = options.info.width;
		this.documentHeight = options.info.height;
		this.canvas.setWidth(options.info.width);
		this.canvas.setHeight(options.info.height);
		this.canvas.clear();
		this.canvas.setViewportTransform([1,0,0,1,0,0]);
		this.canvas.setBackgroundImage(Editor.basedir + 'src/' + options.info.file, (e)=>{
			this.canvas.renderAll();
			Editor.setLoading(false, '');
		});
	}
	
	constructor(el, options){
		this._clipBoard = null;
		this.cif = options.cif;
		this.ean = options.ean;
		
		this.docWidth = options.info.width;
		this.docHeight = options.info.height;
		
		this.zoomLocked = false;
		this.currentColorPicker = null;
		var container = document.querySelector(el);
		this.container = container;
		var canvasContainer = $(container).find('.cover-editor canvas').get(0);
		
		canvasContainer.width = canvasContainer.offsetWidth;
		canvasContainer.height = canvasContainer.offsetHeight;
		
		this.imageUploaderForm = new ImageUploaderForm(this);
		this.svgGallery = new SVGGallery(this);
		
		var propContainer = $(this.container).find('.props');
		propContainer.html('');
		
		
		
		$.contextMenu({
			selector: '.cover-editor',
			items: {
				sendToBack: {
					name: 'Enviar al fondo', 
					callback: (key, opt)=>{
						_editor.sendActiveObjectToBack();
					}
				},
				sendBackwards: {
					name: 'Mover atrás',
					callback: (key, opt)=>{
						_editor.sendActiveObjectBackwards();
					}
				},
				bringForward: {
					name: 'Mover adelante',
					callback: (key, opt)=>{
						_editor.bringActiveObjectForward();
					}
				},
				bringToFront: {
					name: 'Traer al frente', 
					callback: (key, opt)=>{
						_editor.bringActiveObjectToFront();
					}
				},
				group: {
					name: 'Agrupar seleccionados',
					callback: (key, opt)=>{
						_editor.groupSelected();
					}
				},
				ungroup: {
					name: 'Desagrupar seleccionados',
					callback: (key, opt)=>{
						if(_editor.canvas.getActiveObjects().length > 0){
							var ao = _editor.canvas.getActiveObjects()[0];
							if(ao && ao.type === 'group'){
								_editor.ungroup(ao);
							}
						}
					}
				}
			}
		});
		
		this.originalPath = options.info.original;
		this.originalFileName = options.info.o_filename;
		this.documentWidth = options.info.width;
		this.documentHeight = options.info.height;
		
		this.canvas = new fabric.Canvas(canvasContainer, {
			preserveObjectStacking: true
		});
		
		this.canvas.setWidth(options.info.width);
		this.canvas.setHeight(options.info.height);
		
		this.canvas.setBackgroundImage(Editor.basedir + 'src/' + options.info.file, (e)=>{
			this.canvas.renderAll();
		});
		
		this.currentPolygon = new Polygon();
		
		this.canvas.on('mouse:wheel', (e)=>this.onMouseWheel(e));
		this.canvas.on('selection:created', (e)=>this.onSelectObject(e));
		this.canvas.on('selection:updated', (e)=>this.onSelectObject(e));
		this.canvas.on('selection:cleared', (e)=>this.onSelectionCleared(e));
		this.canvas.on('object:modified', (e)=>this.onModifyObject(e));
		this.canvas.on('object:added', (e)=>this.history.updateCanvasState());
		
		document.addEventListener('keydown', (e)=>{
			// ESC
			if(e.which === 27){
				if(this.polygonMode){
					this.isPolygonMode = false;
				}
				if(this.canvas.isDrawingMode){
					this.exitFreehandMode();
				}
			}
			
			// SUPR
			if(e.which === 46){
				this.canvas.getActiveObjects().forEach(o=>this.canvas.remove(o));
				this.canvas.discardActiveObject();
				this.canvas.renderAll();
			}
			
			// CTRL-C
			if(e.which === 67 && e.ctrlKey){
				this.copy();
			}
			// CTRL-V
			if(e.which === 86 && e.ctrlKey){
				this.paste();
			}
			// CTRL-Z
			if(e.which === 90 && e.ctrlKey){
				this.history.undo();
			}
			// CTRL-Y
			if(e.which === 89 && e.ctrlKey){
				this.history.redo();
			}
		});
		
		this.canvas.on('mouse:down', (options)=>{
			if(options.target && _editor.currentPolygon && _editor.currentPolygon.pointArray.length > 0 && options.target.id === _editor.currentPolygon.pointArray[0].id){
				_editor.currentPolygon.generatePolygon(_editor.currentPolygon.pointArray);
			}
			if(_editor.polygonMode){
				_editor.currentPolygon.addPoint(options);
			}
		});
		
		document.body.addEventListener('mousedown', (e)=>{
			if(e.buttons === 2){
				if(this.canvas.isDrawingMode){
					this.exitFreehandMode();
				}else if(this.polygonMode){
					this.isPolygonMode = false;
				}
			}
		}); 
		
		document.body.addEventListener('click', (e)=>{
			if(e && e.path){
				if(e.path.filter(p=>$(p).hasClass('a-color-picker')).length <= 0){
					this.currentColorPicker&&this.currentColorPicker.hide();
				}
			}
		});
		 
		this.propEditor = new PropertyEditor(this.canvas, propContainer);
		
		this.toolbar = new Toolbar('.toolbar', [
			{label:"Caja", icon: 'ce-box', hidelabel: true, callback: (e)=>{this.addBox()}},
			{label:"Texto", icon: 'ce-text', hidelabel: true, callback: (e)=>{this.addText()}},
			{label:"Area de texto", icon: 'ce-textbox', hidelabel: true, callback: (e)=>{this.addTextBox()}},
			{label:"Polígono", isMode: true, icon: 'ce-polygon', hidelabel: true, callback: (e)=>{
				this.isPolygonMode = true;
			}},
			{label:"Círculo", icon: 'ce-circle', hidelabel: true, callback: (e)=>{this.addCircle()}},
			{label:"Imagen", icon: 'ce-picture', hidelabel: true, callback: (e)=>{this.showImageEditor()}},
			{label:'Imagen prediseñada', icon: 'ce-shape', hidelabel: true, callback: (e)=>{this.showSVGGallery()}}
		],[
			{
				label:'Generar portada personalizada', icon: 'ce-share', important: true, hidelabel: true, callback: (e)=>{
					this.bakeDoc().then(res=>{
						this.createVersion({
							cif: this.cif,
							ean: this.ean,
							cid: this.cid,
							vfile: res.output
						}).then((res)=>{
							if(typeof Editor.onVersionCreated == 'function'){
								Editor.onVersionCreated(res);
							}
						});
					}, rej=>{
						console.log(rej);
					});
				}
			}
		]);
		
		this.history = new History(this, {
			undoButton: $(this.container).find('.btn-undo').length>0?$(this.container).find('.btn-undo'):null,
			redoButton: $(this.container).find('.btn-redo').length>0?$(this.container).find('.btn-redo'):null
		});
		
		this.disableContextMenu();
		
		Editor.setLoading(false, '');
		
		Editor.startTour();
	}
	
	createVersion(data){
		return new Promise((resolve, reject)=>{
			$.ajax({
				type: 'POST',
				dataType: 'json',
				url: Editor.endpoint + 'createversion.php',
				contentType: 'application/json',
				data: JSON.stringify(data),
				success: (res)=>{resolve(res)},
				error: ()=>{reject()}
			});
		});
	}
	
	static hide(){
		$('.editor-modal').hide();
	}
	
	static destroy(){
		if(typeof _editor != 'undefined'){
			if(_editor.canvas){
				_editor.canvas.clear();
				_editor.canvas.dispose();
				_editor.canvas = null;
			}
			$(_editor.container).find('.tools div').html('');
			$(_editor.container).find('.props').html('');
			$('.editor-modal').hide();
		}
	}
	
	static loadCover(ean){
		return new Promise((resolve, reject)=>{
			
			$('.editor-modal').show();
			Editor.loadFromEAN(ean).then((doc)=>{
				Editor.setLoading(true, 'Cargando información de la portada&hellip;');
				Editor.getCoverInfo(doc.cif, doc.ean).then(res=>{
					if(window._editor instanceof Editor){
						window._editor.update({cif: doc.cif, ean: doc.ean, info: res});
					}else{
						window._editor = new Editor('.editor-container', {
							cif: doc.cif,
							ean: doc.ean,
							info: res
						});
					}
				});
			})
		});
	}
	
	set isPolygonMode(b){
		_editor.polygonMode = b;
		this.zoomLocked = false;
		if(this.currentPolygon){
			if(b){
				this.exitFreehandMode();
				this.zoomLocked = true;
				this.canvas.setViewportTransform([1,0,0,1,0,0]); 
				this.canvas.backgroundImage.opacity = 0.3;
			}else{
				this.toolbar.el.find('.ce-polygon').parent().removeClass('active');
				this.canvas.backgroundImage.opacity = 1;
				this.currentPolygon.reset(); 
				this.zoomLocked = false;
			}
		}
	}
	
	disableContextMenu(){
		$(this.container).find('.cover-editor').contextMenu(false);
	}
	
	enableContextMenu(){
		$(this.container).find('.cover-editor').contextMenu(true);
	}
	
	freehandMode(){
		this.isPolygonMode = false;
		this.canvas.backgroundImage.opacity = 0.3;
		this.canvas.isDrawingMode = true;
		this.canvas.renderAll();
	}
	
	exitFreehandMode(){
		this.toolbar.el.find('.ce-brush').parent().removeClass('active');
		this.canvas.backgroundImage.opacity = 1;
		this.canvas.isDrawingMode = false;
		this.canvas.renderAll();
	}
	
	addBox(){
		this.isPolygonMode = false;
		var b = new fabric.Rect({left: 100, top: 100, width: 50, height: 50, fill: '#999', strokeWidth: 1, stroke: '#333'});
		b.type = 'box';
		this.canvas.add(b);
		this.canvas.setActiveObject(b);
	}
	
	addImage(file, width, height){
		this.isPolygonMode = false;
		fabric.Image.fromURL(file, (oImg)=>{
			this.canvas.add(oImg);
			this.canvas.setActiveObject(oImg);
		});
	}
	
	addText(){
		this.isPolygonMode = false;
		var t = new fabric.IText('Texto', {
			fontFamily: 'arial',
			left: 100,
			top: 100
		});
		t.type = 'text';
		this.canvas.add(t);
		this.canvas.setActiveObject(t);
	}
	
	addTextBox(){
		this.isPolygonMode = false;
		var tb = new fabric.Textbox('Texto', {
			fontFamily: 'arial',
			left: 100,
			top: 100
		});
		this.canvas.add(tb);
		this.canvas.setActiveObject(tb);
	}
	
	addCircle(){
		this.isPolygonMode = false;
		var c = new fabric.Circle({
			radius: 20,
			fill: '#999',
			stroke: '#333',
			strokeWidth: 1,
			left: 100, top: 100
		});
		this.canvas.add(c);
		this.canvas.setActiveObject(c);
	}
	
	groupSelected(){
		var activeObject = this.canvas.getActiveObject();
		if (activeObject && activeObject.type === 'activeSelection'){
			activeObject.toGroup();
			this.canvas.renderAll();
		}
	}
	
	ungroup(obj){
		if(obj.isType('group')){
			var elements = obj.getObjects();
			obj.destroy();
			this.canvas.remove(obj);
			elements.forEach((el)=>{ this.canvas.add(el); });
			this.canvas.renderAll();
		}
	}
	
	addSVGfromURL(url){
		fabric.loadSVGFromURL(url, (objects, options)=>{
			var svg = fabric.util.groupSVGElements(objects, options);
			
			svg.set({
				top: 100,
				left: 100,
				originX: 'center',
				originY: 'center'
			});
			svg.scaleToWidth(100);
			svg.scaleToHeight(100);
			
			_editor.canvas.add(svg);
			_editor.canvas.renderAll();
		});
	}
	
	copy(){
		var ao = this.canvas.getActiveObject();
		if(ao){
			ao.clone((cloned)=>{
				this._clipBoard = cloned;
			});
		}
	}
	
	paste(){
		if(this._clipBoard){
			this._clipBoard.clone((clonedObj)=>{
				this.canvas.discardActiveObject();
				clonedObj.set({
					left: clonedObj.left + 10,
					top: clonedObj.top + 10,
					evented: true,
				});
				if (clonedObj.type === 'activeSelection') {
					// active selection needs a reference to the canvas.
					clonedObj.canvas = this.canvas;
					clonedObj.forEachObject(function(obj) {
						this.canvas.add(obj);
					});
					// this should solve the unselectability
					clonedObj.setCoords();
				} else {
					this.canvas.add(clonedObj);
				}
				this._clipBoard.top += 10;
				this._clipBoard.left += 10;
				this.canvas.setActiveObject(clonedObj);
				this.canvas.requestRenderAll();
			});
		}
	}
	
	onSelectionCleared(e){
		this.propEditor.clear();
		this.disableContextMenu();
	}
	
	onModifyObject(e){
		var target = e.target;
		if(!target){
			return;
		}
		if(target.type == 'box'){
			var sX = target.scaleX;
			var sY = target.scaleY;
			target.width *= sX;
			target.height *= sY;
			target.scaleX = 1;
			target.scaleY = 1;
			target.dirty = true;
		}
		this.history.updateCanvasState();
	}
	
	onSelectObject(e){
		this.propEditor.getActiveObjectProps();
		this.enableContextMenu();
	}
	
	onMouseWheel(evt){
		if(!this.zoomLocked){
			var delta = evt.e.deltaY;
			var pointer = this.canvas.getPointer(evt.e);
			var zoom = this.canvas.getZoom();
			zoom = zoom - delta/600;
			if(zoom > 20) zoom = 20; 
			if(zoom < 0.1) zoom = 0.1;
			this.canvas.zoomToPoint({x: evt.e.offsetX, y: evt.e.offsetY}, zoom);
		}
		evt.e.preventDefault();
		evt.e.stopPropagation();
	}
	
	bringActiveObjectToFront(){
		var ao = this.canvas.getActiveObject();
		if(ao){ this.canvas.bringToFront(ao); }
	}
	
	bringActiveObjectForward(){
		var ao = this.canvas.getActiveObject();
		if(ao){ this.canvas.sendForward(ao); }
	}
	
	sendActiveObjectToBack(){
		var ao = this.canvas.getActiveObject();
		if(ao){ this.canvas.sendToBack(ao); }
	}
	
	sendActiveObjectBackwards(){
		var ao = this.canvas.getActiveObject();
		if(ao){ this.canvas.sendBackwards(ao); }
	}
	
	static getCoverInfo(cif, ean){
		return new Promise((s, r)=>{
			$.ajax({
				type: 'POST',
				dataType: 'json',
				contentType: 'application/json',
				url: Editor.basedir + 'scripts/getcoverinfo.php',
				data: JSON.stringify({
					cif: cif,
					ean: ean
				}),
				success: (res)=>s(res),
				error: (xhr, err, o)=>r(JSON.parse(xhr.responseText))
			});
		});
	}
	 
	showImageEditor(){
		this.isPolygonMode = false;
		this.imageUploaderForm.show();
	}
	
	showSVGGallery(){
		this.isPolygonMode = false;
		this.svgGallery.show();
	}
	
	bakeDoc(){
		return new Promise((resolve, reject)=>{
			
			Editor.setLoading(true, 'Generando portada personalizada<br />Espere, por favor&hellip;');
			
			this.canvas.setViewportTransform([1,0,0,1,0,0]);
			
			var bgim = _editor.canvas.backgroundImage;
			_editor.canvas.setBackgroundImage(null);
			var svg = _editor.canvas.toSVG();
			
			var re = new RegExp(Editor.basedir + 'sources/', 'g');
			
			svg = svg.replace(re, '');
			
			var doc = new DOMParser().parseFromString(svg, 'image/svg+xml');
			_editor.canvas.backgroundImage = bgim;
			_editor.canvas.renderAll();

			var svgsrc = new XMLSerializer().serializeToString(doc);
			
			var data = {
				ean: this.ean,
				svgsrc: svgsrc,
				document: this.originalPath,
				documentName: this.originalFileName,
				editResolution: 72,
				targetResolution: 300,
				documentWidth: this.canvas.getWidth(),
				documentHeight: this.canvas.getHeight()
			};
			
			$.ajax({
				type: 'POST',
				dataType: 'json',
				data: JSON.stringify(data),
				contentType: 'application/json',
				url: Editor.basedir + 'scripts/bake_doc.php',
				cache: false,
				success: function(res){
					Editor.setLoading(false, '');
					resolve(res);
				},
				error: function(err){
					Editor.setLoading(false, '');
					reject();
				}
			});
		});
	}
};

Editor.config = (o)=>{
	Editor.basedir = '';
	Editor.endpoint = '';
	Editor.onVersionCreated = ()=>{};
	
	if(o && o.basedir) Editor.basedir = o.basedir;
	if(o && o.endpoint) Editor.endpoint = o.endpoint;
	if(o && o.onVersionCreated) Editor.onVersionCreated = o.onVersionCreated;
}

Editor.setLoading = (b, text)=>{
	if(text){ $('.editor-modal .holder>p').html(text); }
	if(b){
		$('.editor-modal').addClass('loading');
	}else{
		$('.editor-modal').removeClass('loading');
	}
};

Editor.setCookie = (name, value, days)=>{
	var expires;
	if(days){
		var date = new Date();
		date.setTime(date.getTime() + (days*24*60*60*1000));
		expires = "; expires=" + date.toGMTString();
	}else{
		expires = "";
	}
	document.cookie = name + '=' + value + expires + '; path=/';
};

Editor.getCookie = (name)=>{
	if(document.cookie.length > 0){
		var c_start = document.cookie.indexOf(name + '=');
		if(c_start != -1){
			c_start = c_start + name.length + 1;
			var c_end = document.cookie.indexOf(';', c_start);
			if(c_end == -1){
				c_end = document.cookie.length;
			}
			return unescape(document.cookie.substring(c_start, c_end));
		}
	}
	return "";
};

Editor.deleteCookie = (name) => {
	if(document.cookie.length > 0){
		document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
	}
};


Editor.startTour = (restart)=>{
	if(restart === true){ Editor.deleteCookie('ceditor_tour_skipped'); Editor.deleteCookie('ceditor_tour_completed'); } 
	if(Editor.getCookie('ceditor_tour_skipped') == '1' || Editor.getCookie('ceditor_tour_completed') == '1'){
		return false;
	}
	hopscotch.startTour({
		id: 'welcome',
		i18n: {
			nextBtn: 'Siguiente',
			prevBtn: 'Anterior',
			doneBtn: 'Terminar',
			skipBtn: 'Saltar'
		},
		onClose: ()=>{
			Editor.setCookie('ceditor_tour_skipped', '1', 60);
		},
		onEnd: ()=>{
			Editor.setCookie('ceditor_tour_completed', '1', 60);
		},
		steps: [
			{
				target: $('.toolbar button').first().get(0),
				placement: 'right',
				title: 'Barra de herramientas',
				content: 'Este área contiene las herramientas de creación disponibles.'
			},
			{
				target: $('.toolbar button:nth-child(1)').get(0),
				placement: 'right',
				title: 'Herramienta Caja',
				content: 'Permite crear un cuadrado o rectangulo.'
			},
			{
				target: $('.toolbar button:nth-child(2)').get(0),
				placement: 'right',
				title: 'Herramienta Texto',
				content: 'Permite crear un texto de línea simple.'
			},
			{
				target: $('.toolbar button:nth-child(3)').get(0),
				placement: 'right',
				title: 'Herramienta Área de texto',
				content: 'Permite crear una caja de texto multilínea.'
			},
			{
				target: $('.toolbar button:nth-child(4)').get(0),
				placement: 'right',
				title: 'Herramienta Polígono',
				content: 'Activa la creación de polígonos. Una vez activada, haciendo clic sobre la portada se crearan los puntos que conforman el polígono, hasta cerrar la forma.'
			},
			{
				target: $('.toolbar button:nth-child(5)').get(0),
				placement: 'right',
				title: 'Herramienta Círculo',
				content: 'Permite crear círculos o elipses.'
			},
			{
				target: $('.toolbar button:nth-child(6)').get(0),
				placement: 'right',
				title: 'Herramienta Imagen',
				content: 'Permite cargar ficheros de imagen.'
			},
			{
				target: $('.toolbar button:nth-child(7)').get(0),
				placement: 'right',
				title: 'Herramienta Imagen prediseñada',
				content: 'Abre la galería de imágenes prediseñadas (SVG)'
			},
			{
				target: $('.props').get(0),
				placement: 'left',
				title: 'Panel de propiedades',
				content: 'En este panel se editan las propiedades de los objetos seleccionados'
			}
		]
	});
};