export default class History{
	constructor(editor, options){
		this.editor = editor;
		
		this.options = Object.assign({
			undoButton: null,
			redoButton: null
		}, options);
		
		this.config = {
			canvasState: [],
			currentStateIndex: -1,
			undoStatus: false,
			redoStatus: false,
			undoFinishedStatus: 1,
			redoFinishedStatus: 1
		};
		
		if(this.options.undoButton){
			this.options.undoButton.click(()=>{this.undo()})
		}
		if(this.options.redoButton){
			this.options.redoButton.click(()=>{this.redo()})
		}
	}
	
	updateCanvasState(){
		if(this.config.undoStatus == false && this.config.redoStatus == false){
			var jsonData = this.editor.canvas.toJSON();
			var canvasAsJSON = JSON.stringify(jsonData);
			if(this.config.currentStateIndex < this.config.canvasState.length-1){
				var indexToBeInserted = this.config.currentStateIndex+1;
				this.config.canvasState[indexToBeInserted] = canvasAsJSON;
				var numberOfElementsToRetain = indexToBeInserted+1;
				this.config.canvasState = this.config.canvasState.splice(0, numberOfElementsToRetain);
			}else{
				this.config.canvasState.push(canvasAsJSON);
			}
			
			this.config.currentStateIndex = this.config.canvasState.length-1;
			if((this.config.currentStateIndex == this.config.canvasState.length-1) && this.config.currentStateIndex != -1){
				if(this.options.redoButton){
					this.options.redoButton.attr('disabled', true);
				}
			}
		}
	}
	
	undo(){
		if(this.config.undoFinishedStatus){
			if(this.config.currentStateIndex == -1){
				this.config.undoStatus = false;
			}else{
				if(this.config.canvasState.length >= 1){
					this.config.undoFinishedStatus = 0;
					if(this.config.currentStateIndex != 0){
						this.config.undoStatus = true;
						this.editor.canvas.loadFromJSON(this.config.canvasState[this.config.currentStateIndex-1], ()=>{
							this.editor.canvas.renderAll();
							this.config.undoStatus = false;
							this.config.currentStateIndex -= 1;
							if(this.options.undoButton){
								this.options.undoButton.removeAttribute('disabled');
							}
							if(this.config.currentStateIndex !== this.config.canvasState.length-1){
								if(this.options.redoButton){
									this.options.redoButton.removeAttribute('disabled');
								}
							}
							
							this.config.undoFinishedStatus = 1;
						});
					}else if(this.config.currentStateIndex == 0){
						this.editor.canvas.clear();
						this.config.undoFinishedStatus = 1;
						if(this.options.undoButton){
							this.options.undoButton.attr('disabled', true);
						}
						if(this.options.redoButton){
							this.options.redoButton.removeAttribute('disabled');
						}
						
						this.config.currentStateIndex -= 1;
					}
				}
			}
		}
	}
	
	redo(){
		if(this.config.redoFinishedStatus){
			if((this.config.currentStateIndex == this.config.canvasState.length-1) && this.config.currentStateIndex != -1){
				if(this.options.redoButton){
					this.options.redoButton.attr('disabled', true);
				}
			}else{
				if(this.config.canvasState.length > this.config.currentStateIndex && this.config.canvasState.length != 0){
					this.config.redoFinishedStatus = 0;
					this.config.redoStatus = true;
					this.editor.canvas.loadFromJSON(this.config.canvasState[this.config.currentStateIndex+1], ()=>{
				    	this.editor.canvas.renderAll();
			    		this.config.redoStatus = false;
						
						this.config.currentStateIndex += 1;
						if(this.config.currentStateIndex != -1){
							if(this.options.undoButton){
								this.options.undoButton.removeAttribute('disabled');
							}
						}
						
						this.config.redoFinishedStatus = 1;
						
						if((this.config.currentStateIndex == this.config.canvasState.length-1) && this.config.currentStateIndex != -1){
							if(this.options.redoButton){
								this.options.redoButton.attr('disabled', true);
							}
						}
					});
				}
			}
		}
	}
};