const ghtml = require('html-loader!./svg_gallery_modal.html'); 

export default class SVGGallery{
	constructor(editor){
		var el = $(ghtml);
		this.el = el;
		this.editor = editor;
		$(editor.container).append(el);
		jQuery('.svg-gallery-modal').modal({show: false});
		this.actionButton = this.el.find('.btn-add-selected');
		this.actionButton.attr('disabled', true);
		this.undraw_load(0).then((res)=>this.render(res));
	}
	
	show(){ 
		jQuery('.svg-gallery-modal').modal('show');
	}
	
	hide(){
		jQuery('.svg-gallery-modal').modal('hide');
	}
	
	render(response){
		this.setLoading(false);
		response.results.forEach((result)=>{
			var item = $('<div class="col-sm-3"><div class="gal-item"><img src="' + result + '" class="img-responsive" /></div></div>');
			item.click((e)=>{this.select(item)});
			this.el.find('.results .results-row').append(item);
		});
		if(response.hasmore){
			var loadmore = $('<div class="col-sm-12 text-center load-more"><button class="btn btn-primary btn-sm">Cargar más&hellip;</button></div>');
			loadmore.click((e)=>{ this.undraw_load(response.nextpage).then((res)=>{$(e.currentTarget).remove(); this.render(res); }); $(e.currentTarget).remove(); });
			this.el.find('.results .results-row').append(loadmore);
		}
	}
	
	setLoading(v){ 
		if(v){
			this.el.addClass('loading');
		}else{
			this.el.removeClass('loading');
		}
	}
	
	select(item){
		this.actionButton.attr('disabled', true);
		
		if(this.selectedItem){
			this.selectedItem.removeClass('selected'); this.selectedItem = null;
		}
		/*
		this.el.find('.gal-item.selected').removeClass('selected');
		item.addClass('selected');
		*/
		item.addClass('selected');
		this.selectedItem = item;
		this.actionButton.removeAttr('disabled');
	}
	
	undraw_load(page){
		this.setLoading(true);
		return $.ajax({
			type: 'GET',
			dataType: 'json',
			url: Editor.basedir + 'scripts/undraw_load.php?page='+page
		});
	}
	
	addSelected(){
		if(this.selectedItem){
			_editor.addSVGfromURL(this.selectedItem.find('img').attr('src'));
		}
	}
}