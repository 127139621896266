import Cropper from 'cropperjs';
const iuhtml = require('html-loader!./iu.html');

export default class ImageUploaderForm{ 
	constructor(editor){
		var el = $(iuhtml);
		this.el = el;
		this.editor = editor;
		$(editor.container).append(el);
		jQuery('.image-editor-modal').modal({show: false});
		
		el.find('#imageeditor_file').change((e)=>{
			var fileInput = e.currentTarget;
			var file = (fileInput.files && fileInput.files.length > 0)?fileInput.files[0]:null;
			if(file){
				var fr = new FileReader();
				fr.onload = function(evt){
					var old_img = el.find('#cropper_image').get(0);
					if(old_img){
						if(old_img.cropper){ old_img.cropper.destroy(); }
						$(old_img).remove();
					}
					var img = document.createElement('IMG');
					img.id = 'cropper_image';
					img.src = evt.target.result;
					img.mimetype = file.type;
					el.find('.image-preview').append(img);
					img.cropper = new Cropper(img, {
						viewMode: 1,
						ready: function(){
							img.cropper.setCropBoxData({left: 0, top: 0, width: img.cropper.container.clientWidth, height: img.cropper.container.clientHeight});
						}
					});
				};
				
				fr.readAsDataURL(file);
			}
		});
	}
	
	show(){
		jQuery('.image-editor-modal').modal('show');
	}
	
	hide(){
		jQuery('.image-editor-modal').modal('hide');
	}
	
	dataURItoBlob(dataURI){
		// convert base64/URLEncoded data component to raw binary data held in a string
		var byteString;
		if (dataURI.split(',')[0].indexOf('base64') >= 0)
			byteString = atob(dataURI.split(',')[1]);
		else
			byteString = unescape(dataURI.split(',')[1]);

		// separate out the mime component
		var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

		// write the bytes of the string to a typed array
		var ia = new Uint8Array(byteString.length);
		for (var i = 0; i < byteString.length; i++) {
			ia[i] = byteString.charCodeAt(i);
		}

		return new Blob([ia], {type:mimeString});
	}
	
	uploadImage(){
		var img = this.el.find('#cropper_image').get(0);
		var imgurl = img.cropper.getCroppedCanvas().toDataURL(img.mimetype);
		
		var fd = new FormData();
		fd.append("uploader_file", this.dataURItoBlob(imgurl));
		
		$.ajax({
			url: Editor.basedir + 'scripts/upload_image.php',
			type: 'POST',
			dataType: 'json',
			data: fd,
			contentType: false,
			cache: false,
			processData: false,
			success: (data)=>{
				this.editor.addImage(Editor.basedir + data.file, data.width, data.height);
			}
		});
	}
};